@import '../../03-mixins/typography';
@import '../../03-mixins/forms';

.ms-order-details {
    margin-top: var(--module-margin-top);
    margin-bottom: var(--module-margin-bottom);

    &__heading {
        margin: var(--spacer-4) 0;
    }

    &__section-heading {
        margin-top: var(--spacer-2);
        margin-bottom: var(--spacer-2);
    }

    &__address-information-title {
        @include label;
        width: 100%;
    }

    &__block-item {
        white-space: nowrap;
    }

    &__text-overflow-ellipses {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__text-auto-hyphens {
        hyphens: auto;
    }

    &__delivery-number {
        @include add-icon(route, before, $size: 1rem);
        &:before {
            margin-right: var(--spacer-2);
        }
    }

    &__text-customer-po {
        word-wrap: break-word;
    }

    &__link-pointer {
        cursor: pointer;
    }

    &__reorder-button {
        margin-bottom: var(--spacer-2);
    }

    &__download-link {
        cursor: pointer;
    }

    &__delivery-information-infobox {
        background: var(--color-bg-tertiary);
        padding: var(--spacer-1);
    }

    &__delivery-information {
        .table {
            > tbody > tr > td {
                padding: var(--spacer-1);
                width: 50%;
            }
        }
    }

    .msc-section-box {
        .col-md-3 {
            margin-bottom: var(--spacer-2);
        }
    }
}
