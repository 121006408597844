@import 'bootstrap/scss/mixins/grid-framework';
@import 'bootstrap/scss/mixins/breakpoints';
@import '../../01-vendors/variables-custom';
@import '../../03-mixins/typography';

// Compensate "default" row HTML element
h1,
h2,
h3,
h4,
h5,
h6 {
    &.container-heading {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        padding-top: var(--spacer-2);
        width: 100%;
        text-align: center;
    }
}

// h1 in combination with headline of container is used for "system pages" (create order, checkout, ...) as a page title in combination with breadcrumb module (both visually inside of a block container)
h1 {
    &.container-heading {
        color: var(--color-text-secondary-inverted);
        background: var(--color-bg-secondary);
        text-align: left;
        padding-bottom: var(--spacer-2);
        margin: 0 auto;
        @include font-heading-h1();

        @include media-breakpoint-up(xl) {
            max-width: var(--grid-container-max-width-xl);
        }
    }

    // Special use-case: Page title (<h1>) with breadcrumb underneath
    + .col-12 {
        padding-left: 0;
        padding-right: 0;
    }
}

h2 {
    &.container-heading {
        @include font-heading-h2();
    }
}

// h3 {
// 	&.container-heading {
// 	}
// }

// h4 {
// 	&.container-heading {
// 	}
// }

// h5 {
// 	&.container-heading {
// 	}
// }

// h6 {
// 	&.container-heading {
// 	}
// }
