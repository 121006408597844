@import '../../03-mixins/typography';

.ms-account-profile {
    margin-top: var(--module-margin-top);
    margin-bottom: var(--module-margin-bottom);

    &__section {
        margin-bottom: var(--spacer-3);
    }

    &__toggle-enable-text {
        font-weight: var(--font-weight-bold);
        padding-left: var(--spacer-1);
    }
}
