// General styles and options of module
.ms-image-list {
    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }
}

// Media-Icons layout = Site builder: Media-Icons
// Styles for the root element of the module itself
[data-m-layout='media-icons'].ms-image-list {
    margin-top: var(--module-margin-top);
    margin-bottom: var(--module-margin-bottom);
}

// Styles for all BEM element selectors of the module
[data-m-layout='media-icons'] .ms-image-list {
    &__title {
        margin-bottom: var(--spacer-3);
    }

    &__list {
        display: flex;
        list-style: none;
        gap: var(--spacer-3);
        justify-content: center;
        padding: 0;
    }
}
