/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import 'bootstrap/scss/functions';
@import 'variables-custom';
@import 'bootstrap/scss/mixins';
// @import "bootstrap/scss/root";
@import 'reboot-custom';
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'table-extension';

// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
@import 'bootstrap/scss/transitions';
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group";
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
@import 'bootstrap/scss/alert';
@import 'alert-extension';
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
@import 'bootstrap/scss/toasts';
@import 'toast-extension';
// @import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import 'bootstrap/scss/utilities';
// We only import a subset of utilities
@import 'bootstrap/scss/utilities/screenreaders';
@import 'bootstrap/scss/utilities/spacing';
@import 'bootstrap/scss/print';
