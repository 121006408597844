@import '../01-vendors/variables-custom';
@import 'bootstrap/scss/mixins/grid-framework';
@import 'bootstrap/scss/mixins/breakpoints';

// Maximum width of the "normal" content container. Used to limit the website content (header, main, footer)

// Attention: This is an example for a fluid website layout, if you want to have an adaptive please uncomment the max-width lines

@mixin container-layout {
    max-width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        // max-width: var(--grid-container-max-width-sm);
    }

    @include media-breakpoint-up(md) {
        // max-width: var(--grid-container-max-width-md);
    }

    @include media-breakpoint-up(lg) {
        // max-width: var(--grid-container-max-width-lg);
    }

    @include media-breakpoint-up(xl) {
        max-width: var(--grid-container-max-width-xl);
    }
}

@mixin container-layout-fluid {
    max-width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        // max-width: var(--grid-container-max-width-sm);
    }

    @include media-breakpoint-up(md) {
        // max-width: var(--grid-container-max-width-md);
    }

    @include media-breakpoint-up(lg) {
        // max-width: var(--grid-container-max-width-lg);
    }

    @include media-breakpoint-up(xl) {
        margin-right: 18.125rem;
        margin-left: 18.125rem;
    }

}