@import '../../03-mixins/typography';

.lxs-contact {
    color: var(--color-text-primary-inverted);
    background-color: var(--color-bg-secondary);
    padding: var(--spacer-4);
    margin-bottom: var(--module-margin-bottom);

    &__title {
        @include add-icon(user, before, $size: 1rem);

        &::before {
            margin-right: var(--spacer-1);
        }
    }

    &__name {
        margin-bottom: var(--spacer-4);
    }

    &__position {
        margin-bottom: var(--spacer-5);
    }

    &__phonenumber {
        margin-bottom: var(--spacer-3);
        @include add-icon(phone, before, $size: 1rem);

        &::before {
            margin-right: var(--spacer-1);
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        @include add-icon(mail, before, $size: 1rem);
        background-color: var(--color-bg-secondary);
        border-color: var(--color-text-primary-inverted);
        color: var(--color-text-primary-inverted);

        &::before {
            margin-right: var(--spacer-1);
            line-height: 1.5;
        }

        &:hover {
            background: var(--color-text-primary-inverted);
            color: var(--color-bg-secondary);
        }
    }
}
