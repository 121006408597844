@import '../../01-vendors/variables-custom';
@import '../../03-mixins/typography';

.lxs-tooltip {
	cursor: pointer;
	white-space: nowrap;
	@include add-icon(info, after, $size: 1rem);

	&::after {
		margin-right: var(--spacer-2);
		margin-left: var(--spacer-2);
	}

	&:hover::after {
		color: var(--color-link-primary-hover);
	}
}

.lxs-tooltip__content {
	$content-width: 300px;
	width: $content-width;
	border: $tooltip-content-border-width solid $tooltip-content-border-color;
	background-color: var(--color-bg-tertiary);
	color: var(--color-text-primary);
	text-align: left;
	padding: var(--spacer-2);
	position: absolute;
	z-index: $zindex-tooltip;
	pointer-events: none;

	&-status-code {
		text-align: left;
		padding: var(--spacer-2);

		div {
			margin-bottom: var(--spacer-2);
		}
	}
}
