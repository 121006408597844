@import '../../03-mixins/icon';

.msc-table-sorting {
	display: inline-flex;
	cursor: pointer;
	position: relative;
	padding-right: var(--spacer-4);

	&::after {
		position: absolute;
		transform: translateY(-50%);
		right: calc(var(--spacer-2) * -1);
		top: 50%;
	}

	&--default {
		@include add-icon(sort, after, $size: 1rem);
	}

	&--asc {
		@include add-icon(chevron, after, $size: 0.75rem, $transform: translateY(-50%) translatex(-2px) rotate(-90deg));
	}

	&--desc {
		@include add-icon(chevron, after, $size: 0.75rem, $transform: translateY(-50%) translatex(-2px) rotate(90deg));
	}
}