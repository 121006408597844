@import '../01-vendors/variables-custom';

:root {
    --font-size-base: 20px;
    --font-size-site-picker: 14px;
    --font-size-site-picker-layer: 18px;
    --font-size-account-info: 14px;
    --font-size-account-info-layer: 18px;
    --font-size-pagination: 14px;
    --font-size-table-head: 14px;
    --font-size-table-body: 18px;
    --font-size-text: 20px;
    --font-size-h1: 38px;
    --font-size-h2: 28px;
    --font-size-h3: 25px;
    --font-size-h4: 22px;
    --font-size-button: 14px;
    --font-size-form-legend: 14px;
    --font-size-navigation-main-1: 14px;
    --font-size-navigation-main-2: 18px;
    --font-size-navigation-main-3: 18px;
    --font-size-navigation-mobile: 18px;
    --font-size-navigation-footer: 20px;
    --font-size-process-bar: 14px;
    --font-size-address: 20px;
    --font-line-height-base: 1.5;
    --font-line-height-h1: 48px;
    --font-line-height-h2: 33px;
    --font-line-height-h3: 25px;
    --font-line-height-h4: 19px;
    --font-line-height-process-bar: 21px;
    --font-line-height-site-picker: 21px;
    --font-line-height-site-picker-layer: 32px;
    --font-line-height-account-info: 21px;
    --font-line-height-account-info-layer: 32px;
    --font-line-height-site-pagination: 21px;
    --font-line-height-table-head: 21px;
    --font-line-height-table-body: 24px;
    --font-line-height-pagination: 21px;
    --font-line-height-text: 31px;
    --font-line-height-button: 21px;
    --font-line-height-form-legend: 21px;
    --font-line-height-navigation-main-1: 21px;
    --font-line-height-navigation-main-2: 32px;
    --font-line-height-navigation-main-3: 32px;
    --font-line-height-navigation-mobile: 32px;
    --font-line-height-navigation-footer: 24px;
    --font-line-height-input: 29px;
    --font-line-height-address: 24px;
    --font-weight-normal: #{$font-weight-normal};
    --font-weight-bold: #{$font-weight-bold};
    --font-family-sans-serif: Lanxess, Helvetica, Verdana, Tahoma, sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --font-family-base: var(--font-family-sans-serif);
}
