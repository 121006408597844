@import '../02-settings/color';

:root {
    --color-text-primary: #{$color-text-primary};
    --color-text-secondary: rgba(128, 128, 128, 1);
    --color-text-tertiary: rgb(186, 68, 68);
    --color-text-primary-inverted: rgba(255, 255, 255, 1);
    --color-text-secondary-inverted: rgba(255, 255, 255, 1);
    --color-text-tertiary-inverted: rgba(255, 255, 255, 1);
    --color-link-primary: rgba(241, 26, 41, 1);
    --color-link-primary-hover: rgba(164, 0, 28, 1);
    --color-link-secondary: rgba(160, 160, 160, 1);
    --color-link-secondary-hover: rgba(79, 73, 76, 1);
    --color-bg-primary: rgba(255, 255, 255, 1);
    --color-bg-secondary: rgba(0, 0, 0, 1);
    --color-bg-tertiary: rgba(239, 239, 239, 1);
    --color-info: rgba(4, 138, 205, 1);
    --color-success: rgba(25, 134, 83, 1);
    --color-warning: rgba(255, 193, 5, 1);
    --color-danger: rgba(220, 56, 72, 1);
    --color-btn-primary-bg: rgba(241, 26, 41, 1);
    --color-btn-secondary-bg: rgb(255, 255, 255);
    --color-btn-primary-bg-hover: rgba(164, 0, 28, 1);
    --color-btn-primary-bg-disabled: rgba(241, 26, 41, 0.5);
    --color-btn-secondary-bg-hover: rgba(0, 0, 0, 1);
    --color-btn-secondary-bg-active: rgba(0, 0, 0, 1);
    --color-btn-primary-bg-active: rgba(164, 0, 28, 1);
    --color-btn-secondary-disabled: rgb(255, 255, 255);
    --color-btn-primary-text: rgba(255, 255, 255, 1);
    --color-btn-secondary-text: rgba(0, 0, 0, 1);
    --color-btn-primary-text-hover: rgba(255, 255, 255, 1);
    --color-btn-secondary-text-hover: rgba(255, 255, 255, 1);
    --color-btn-secondary-bg-disabled: rgb(255, 255, 255);
    --color-btn-primary-border: transparent;
    --color-btn-secondary-border: rgba(0, 0, 0, 1);
    --color-btn-primary-border-hover: transparent;
    --color-btn-secondary-border-hover: rgba(0, 0, 0, 1);
    --color-btn-primary-border-disabled: transparent;
    --color-btn-secondary-border-disabled: rgba(0, 0, 0, 0.5);
    --color-btn-primary-text-disabled: rgba(255, 255, 255, 1);
    --color-btn-secondary-text-disabled: rgba(0, 0, 0, 0.5);
    --color-table-border-color: rgba(0, 0, 0, 0.2);
    --color-navigation-text-1: $color-text-primary;
    --color-navigation-text-hover-1: var(--color-link-primary);
    --color-navigation-text-2: $color-text-primary;
    --color-navigation-text-hover-2: var(--color-link-primary);
    --color-navigation-text-3: $color-text-primary;
    --color-navigation-text-hover-3: var(--color-link-primary);
    --color-navigation-mobile-text: $color-text-primary;
    --color-navigation-mobile-text-hover: var(--color-link-primary);
    --color-footer-text: var(--color-text-secondary);
    --color-footer-text-hover: var(--color-link-secondary);
    --color-input-border: var(--color-text-secondary);
    --color-input-bg: var(--color-bg-primary);
    --color-input-placeholder: var(--color-text-secondary);
    --color-input-checked: var(--color-link-primary);
    --color-site-picker-text: var(--color-text-primary);
    --color-site-picker-text-hover: var(--color-link-primary-hover);
    --color-account-info-text: var(--color-text-primary);
    --color-account-info-text-hover: var(--color-link-primary-hover);
}
