@import '../../03-mixins/icon';
@import '../../03-mixins/typography';

.msc-pagination {
    display: flex;
    align-items: center;
    margin-top: var(--spacer-4);
    justify-content: flex-end;

    &__button {
        display: flex;
        align-items: center;

        &--is-previous {
            @include add-icon(chevron, before, $size: 1rem, $transform: rotate(180deg));
        }

        &--is-next {
            @include add-icon(chevron, before, $size: 1rem);
        }
    }

    &__page-count {
        display: flex;
        margin: 0 var(--spacer-2);
        @include font-pagination;
    }
}
