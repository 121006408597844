@import '../../01-vendors/variables-custom';
@import 'bootstrap/scss/mixins/grid-framework';
@import 'bootstrap/scss/mixins/breakpoints';
@import '../../02-settings/configuration';
@import '../../03-mixins/container';

body {
    min-height: 100vh;
}

body,
#renderPage {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
}

// Emulatate Bootstrap's container* classes
main,
#subfooter {
    // Applied with main container that holds all content in a row inside of Sitebuilder
    .default-container.container {
        @include container-layout();
    }

    // Module "Container" layout: "Fill screen" - simulate Bootstrap .container-fluid
    .default-container.fluid {
        @include make-container();
        max-width: 100%;
    }

    // Module: "Container" layout: "Fill container"
    .container {
        @include make-container();
    }

    // Module: "Container with 2 columns"
    // .default-container-2-slots {}

    // Module: "Container with 3 columns"
    // .default-container-3-slots {}
}

main {
    padding-top: $header-height;
    flex-grow: 1;
}

header,
footer {
    width: 100%;
}

// Footer has no component scope like ms-footer or similar
// As an alternative to this approach we could apply ms-footer__* classes inside of Sitebuilder
footer {
    > .default-container {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        max-width: var(--grid-container-max-width-xl);
        margin: 0 auto;
        border-top: $border-width / 2 solid #58595b; // TODO: move to Figma variables or replace with existing color token
        background: var(--color-bg-secondary);

        div[class^='default-container'] {
            margin: 0 auto;
        }
    }
}

// header .default-container>.row,
footer .default-container > .row .row {
    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    // Based on https://github.com/twbs/bootstrap/blob/v4.6.2/scss/_grid.scss#L55
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}
