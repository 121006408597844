.ms-spacer {
    &1x {
        height: var(--spacer-1);
    }

    &2x {
        height: var(--spacer-2);
    }

    &3x {
        height: var(--spacer-3);
    }

    &4x {
        height: var(--spacer-4);
    }

    &5x {
        height: var(--spacer-5);
    }

    &6x {
        height: var(--spacer-6);
    }

    &7x {
        height: var(--spacer-7);
    }

    &8x {
        height: var(--spacer-8);
    }

    &9x {
        height: var(--spacer-9);
    }

    &10x {
        height: var(--spacer-10);
    }
}
