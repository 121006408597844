@import 'bootstrap/scss/mixins/breakpoints';

$icons: arrow, calendar, cart, check, chevron, chevron-down, filter, mail, minus, phone, plus, search, truck, user, warning, globe, route;

.cip-cta-link {
    &.alignment__center {
        display: table;
        margin: 0 auto;
    }

    // &.alignment__left {}

    &.alignment__right {
        display: table;
        margin-left: auto;
    }
}

@include media-breakpoint-down(sm) {
    [class*='cip-cta-link--icon-before-'] {
        &::before {
            margin-right: 0 !important;
        }
    }

    [class*='cip-cta-link--icon-after-'] {
        &::after {
            margin-left: 0 !important;
        }
    }
}

@each $icon in $icons {
    .cip-cta-link--icon-before-#{$icon} {
        @include add-icon($icon, before);

        &::before {
            margin-right: var(--spacer-2);
        }
    }

    .cip-cta-link--icon-after-#{$icon} {
        @include add-icon($icon, after);

        &::after {
            margin-left: var(--spacer-2);
        }
    }
}
