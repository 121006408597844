@import '../../01-vendors/variables-custom';

.msc-loading-btn {
    $spinner-size: 24px;
    display: flex !important;
    align-items: center;

    &__spinner {
        width: $spinner-size;
        height: $spinner-size;
        border: $border-width * 2 solid var(--color-btn-primary-text);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
