@import './typography';

@mixin rich-text-styles {
    // Apply heading styles based on HTML elements
    h1 {
        margin-top: var(--spacer-6);
        margin-bottom: var(--spacer-5);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: var(--spacer-3);
        margin-bottom: var(--spacer-3);
    }

    p {
        @include font-text;
    }

    a {
        color: var(--color-link-primary);
        &:hover {
            color: var(--color-link-primary-hover);
        }
    }

    ul {
        li {
            &::marker {
                content: '■  ';
                color: var(--color-btn-primary-bg);
            }
        }
    }

    ol {
        //
    }
}
