@import 'bootstrap/scss/mixins/breakpoints';
@import '../../03-mixins/links';
@import '../../03-mixins/typography';
@import '../../03-mixins/forms';
@import '../../03-mixins/icon';

$flyout-btn-size: 30px;
$flyout-container-height: 13rem;

.lxs-create-order {
    // no margin-top because of the processbar
    margin-bottom: var(--module-margin-bottom);
    overflow: hidden;

    &__search {
        &--small {
            max-width: 100%;
            transition: $transition-base;
            @include media-breakpoint-up(lg) {
                max-width: calc((100% / 1.5) - var(--spacer-4) + #{$flyout-btn-size});
            }
        }

        &--large {
            max-width: 100%;
            transition: $transition-base;
            @include media-breakpoint-up(lg) {
                max-width: calc(100% - var(--container-padding) - #{$flyout-btn-size});
            }
        }
    }

    &__heading {
        margin: var(--spacer-4) var(--spacer-0);
    }

    &__back-button {
        margin-top: var(--spacer-4);
        margin-bottom: var(--spacer-1);
    }

    &__total-entries {
        margin-top: var(--spacer-4);
        margin-bottom: var(--spacer-4);
    }

    &__search-title,
    &__filter-title {
        &::before {
            margin-right: var(--spacer-2);
        }
    }

    &__search-title {
        @include add-icon(search, before, $size: 1.5rem);
    }

    &__filter-title {
        @include add-icon(filter, before, $size: 1.5rem);
    }

    &__select-button,
    &__add-to-cart-button,
    &__copy-button,
    &__remove-button {
        @include link;
        white-space: nowrap;
        &::before {
            margin-right: var(--spacer-2);
        }
    }

    &__add-to-cart-button {
        @include add-icon(cart, before, $size: 1rem);
    }

    &__copy-button {
        @include add-icon(duplicate, before, $size: 1rem);
    }

    &__remove-button {
        @include add-icon(cross, before, $size: 1rem);
        display: flex;
        align-items: center;
    }

    &__selected-products-action {
        .lxs-create-order__copy-button,
        .lxs-create-order__remove-button {
            margin: var(--spacer-1);
            display: flex;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                display: inline-block;
                margin: var(--spacer-0);
            }
        }
    }

    &__global-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @include media-breakpoint-up(md) {
            margin-left: var(--spacer-4);
            width: unset;
        }
    }

    &__global-date-input {
        margin-bottom: var(--spacer-0);
    }

    &__global-date-btn {
        margin-left: var(--spacer-2);
        margin-bottom: var(--spacer-0);
    }

    &__selected-products-heading-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }

        h3 {
            margin-right: var(--spacer-4);
        }
    }

    &__alert {
        display: flex;
        justify-content: space-between;
        align-items: center;

        // Used for full-width alert messages
        &-container {
            margin-top: var(--spacer-4);
        }

        &-message {
            @include add-icon(warning, before, $size: var(--icon-size));

            &::before {
                margin-right: var(--spacer-2);
            }
        }

        &-explanation {
            margin-bottom: var(--spacer-4);
        }
    }

    &__direction-button-wrapper {
        width: 100%;
        margin-top: var(--spacer-4);
        display: flex;
        justify-content: space-between;
    }

    &__disclaimer {
        width: 100%;
        display: grid;
        justify-items: end;

        &-container {
            margin-top: var(--spacer-2);
            display: flex;
            justify-content: space-between;
            justify-items: center;
            align-items: center;

            .msc-form__check {
                margin-bottom: 0;
            }
        }
    }

    &__confirmation-alert {
        background-color: var(--color-success);
        color: var(--color-text-primary-inverted);
        padding: var(--spacer-4);
        margin-bottom: var(--spacer-3);

        h1 {
            margin-bottom: var(--spacer-3);
        }
    }

    &__order-info {
        row-gap: var(--spacer-4);
    }

    &__highlight-search-term {
        background-color: var(--color-warning);
    }

    &__acknowledgement-action-buttons {
        float: right;
        margin-top: var(--spacer-3);
    }

    &__address-information-title {
        @include label;
        width: 100%;
    }

    &__address-information {
        .msc-section-box__title {
            @include add-icon(address-card, before, $size: 1.5rem);
        }
    }

    &__header-info-section,
    &__address-information.msc-section-box {
        min-height: calc(#{$flyout-container-height} * 2);
    }

    &__header-info-customer-po {
        word-break: break-word;
    }

    &__text-auto-hyphens {
        overflow: auto;
        hyphens: auto;
    }

    .msc-section-box__title {
        &:not(:first-of-type) {
            margin-top: var(--spacer-4);
        }

        &:before {
            margin-right: var(--spacer-4);
            margin-top: var(--spacer-1);
        }
    }

    @include media-breakpoint-up(lg) {
        &__show-address-btn {
            @include add-icon(chevron, after, $size: 1rem, $background: var(--color-text-primary));
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding-top: var(--spacer-4);
            position: absolute;
            width: $flyout-btn-size;
            background-color: var(--color-bg-tertiary);
            height: $flyout-container-height;
            transition: $transition-base;
            color: var(--color-text-secondary-inverted);

            &--closed {
                right: var(--container-padding);

                &::after {
                    transform: rotate(180deg);
                }
            }

            &--open {
                right: calc((100% / 3) - var(--spacer-4) * 1.5);
                transition: $transition-base;
            }

            &:after {
                margin-top: var(--spacer-1);
            }
        }

        &__address-information-wrapper {
            position: absolute;
            right: 0;
            transition: $transition-base;

            &--hide {
                right: -100vw;
            }

            &--show {
                width: calc((100% / 3) - var(--spacer-4) * 1.5);
            }
        }
    }
}
