@import '../../01-vendors/variables-custom';
@import '../../03-mixins/rich-text-styles';
@import '../../03-mixins/icon';
@import '../../03-mixins/typography';

.ms-content-block {
    margin-bottom: var(--module-margin-bottom);
}

// Full Width layout = Site builder: Full width
// Styles for the root element of the module itself
[data-m-layout='full-width'].ms-content-block {
    position: relative;
}

[data-m-layout='full-width'].ms-content-block.texttheme__dark {
    &__title {
        color: var(--color-text-primary);
    }

    &__text {
        p {
            color: var(--color-text-secondary-inverted);
        }
    }
}

// Styles for all BEM element selectors of the module
[data-m-layout='full-width'] .ms-content-block {
    &__image {
        .msc_image {
            width: 100%;
        }
    }

    &__details {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0 var(--spacer-5);
    }

    &__text {
        @include rich-text-styles();
        margin-bottom: var(--spacer-1);

        p {
            color: var(--color-text-secondary-inverted);
        }
    }

    &__title {
        color: var(--color-text-secondary-inverted);
        margin-bottom: var(--spacer-3);
    }
}

// Tile layout = Site builder: Tile *
// Styles for the root element of the module itself
[data-m-layout='tile-1-1'].ms-content-block,
[data-m-layout='tile-3-2'].ms-content-block {
    height: 100%;
    display: flex;
    flex-direction: column;

    .msc_image {
        transition: transform 0.3s;
    }

    &:hover .msc_image {
        transform: scale(1.1);
    }
}

[data-m-layout='tile'].ms-content-block.texttheme__dark {
    &__title {
        color: var(--color-text-primary);
    }

    &__text {
        p {
            color: var(--color-text-secondary-inverted);
        }
    }

    .msc_image {
        width: 100%;
    }
}

// Styles for all BEM element selectors of the module
[data-m-layout='tile-1-1'] .ms-content-block,
[data-m-layout='tile-3-2'] .ms-content-block {
    &__details {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        border-bottom: $border-width / 2 solid var(--color-text-secondary);
        margin-bottom: var(--spacer-4);
    }

    &__image {
        overflow: hidden;

        .msc_image {
            width: 100%;
        }
    }

    &__text {
        margin-top: var(--spacer-2);
        margin-bottom: auto;
    }

    &__title {
        margin-top: var(--spacer-2);
    }

    &__cta {
        margin-top: var(--spacer-3);
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include add-icon(arrow, after, $size: 1.25rem);

        // Arrow styles
        &::after {
            color: var(--color-btn-primary-bg);
        }

        &:hover::after {
            color: var(--color-link-primary-hover);
        }

        .msc-cta__primary {
            color: var(--color-btn-primary-bg);
            background-color: transparent;
            padding-left: 0;
            box-shadow: none;
            border: none;
            display: block;
            z-index: 1;
            position: unset;
            @include font-cta-link;

            &:hover {
                color: var(--color-link-primary-hover);
            }

            // Styles to make whole module clickable
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: ' ';
            }

            &:focus {
                outline: none;
            }
        }
    }
}
