@import 'bootstrap/scss/mixins/screen-reader';
@import '../../01-vendors/variables-custom';
@import '../../03-mixins/icon';

.cip-back-top-footer {
	@include add-icon(arrow, after, $size: 1.25rem, $transform: rotate(270deg));
	background-color: var(--color-bg-tertiary);
	color: var(--color-text-secondary);
	position: fixed;
	z-index: $zindex-back-to-top;
	right: var(--spacer-5);
	bottom: var(--spacer-5);
	border-radius: var(--btn-circle);
	box-shadow: $box-shadow;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
	width: var(--btn-circle);
	height: var(--btn-circle);
	opacity: 0;
	transform: translateY(100px);

	&.is-visible {
		opacity: 1;
		transform: translateY(0);
	}

	&:hover {
		color: var(--color-link-primary);
	}
}
