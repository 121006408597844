@import '../01-vendors/variables-custom';

$font-path: '../../../master-assets/fonts';

@font-face {
    font-family: Lanxess;
    src: url('#{$font-path}/lanxess-regular.woff2') format('woff2');
    font-weight: $font-weight-normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: Lanxess;
    src: url('#{$font-path}/lanxess-bold.woff2') format('woff2');
    font-weight: $font-weight-bold;
    font-style: normal;
    font-display: auto;
}
