@import '../01-vendors/variables-custom';
@import '../03-mixins/typography';

// Borrowed from https://github.com/twbs/bootstrap/blob/a1bae2e784139fe625ba744fa45d44c29de7b255/scss/mixins/_border-radius.scss#L4C1-L15C2
// Helper function to replace negative values with 0
@function valid-radius($radius) {
    $return: ();

    @each $value in $radius {
        @if type-of($value) ==number {
            $return: append($return, max($value, 0));
        } @else {
            $return: append($return, $value);
        }
    }

    @return $return;
}

// Helper function to check value against a given minimum value or return null
@function min-value($value, $minValue) {
    $return: ();

    @each $value in $value {
        @if type-of($value) ==number {
            $return: append($return, min($value, $minValue));
        } @else {
            $return: null;
        }
    }

    @return $return;
}

@mixin button-primary {
    @include font-button;
    position: relative;
    display: inline-block;
    padding: var(--spacer-2) var(--spacer-4);
    background-color: var(--color-btn-primary-bg);
    color: var(--color-btn-primary-text);
    cursor: pointer;

    @if min-value($btn-border-width, 0) {
        border: $btn-border-width solid var(--color-btn-primary-border);
    }

    @if $enable-rounded {
        border-radius: valid-radius($border-radius);
    }

    @if $enable-shadows {
        box-shadow: $box-shadow;
    }

    &:hover {
        color: var(--color-btn-primary-text-hover);
        background-color: var(--color-btn-primary-bg-hover);
        border-color: var(--color-btn-primary-border-hover);
    }

    &:focus {
        color: var(--color-btn-primary-text-focus);
        background-color: var(--color-btn-primary-bg-focus);
        border-color: var(--color-btn-primary-border-focus);
    }

    &:active {
        color: var(--color-btn-primary-text-active);
        background-color: var(--color-btn-primary-bg-active);
        border-color: var(--color-btn-primary-border-active);
    }

    &:disabled {
        color: var(--color-btn-primary-text-disabled);
        background-color: var(--color-btn-primary-bg-disabled);
        border-color: var(--color-btn-primary-disabled);
        cursor: not-allowed;
    }
}

@mixin button-secondary {
    @include font-button;
    position: relative;
    display: inline-block;
    padding: var(--spacer-2) var(--spacer-4);
    background-color: var(--color-btn-secondary-bg);
    color: var(--color-btn-secondary-text);
    cursor: pointer;

    @if min-value($btn-border-width, 0) {
        border: $btn-border-width solid var(--color-btn-secondary-border);
    }

    @if $enable-rounded {
        border-radius: valid-radius($border-radius);
    }

    @if $enable-shadows {
        box-shadow: $box-shadow;
    }

    @if $enable-rounded {
        border-radius: valid-radius($border-radius);
    }

    &:hover {
        color: var(--color-btn-secondary-text-hover);
        background-color: var(--color-btn-secondary-bg-hover);
        border-color: var(--color-btn-secondary-border-hover);
    }

    &:focus {
        color: var(--color-btn-secondary-text-focus);
        background-color: var(--color-btn-secondary-bg-focus);
        border-color: var(--color-btn-secondary-border-focus);
    }

    &:active {
        color: var(--color-btn-secondary-text-active);
        background-color: var(--color-btn-secondary-bg-active);
        border-color: var(--color-btn-secondary-border-active);
    }

    &:disabled {
        color: var(--color-btn-secondary-text-disabled);
        background-color: var(--color-btn-secondary-bg-disabled);
        border-color: var(--color-btn-secondary-disabled);
        cursor: not-allowed;
    }
}
