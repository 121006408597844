@import '../../01-vendors/variables-custom';
@import '../../03-mixins/icon';
@import '../../03-mixins/typography';

.ms-nav {
	&__close-button {
		button {
			position: absolute;
			right: var(--spacer-2);
			top: var(--spacer-2);
			@include add-icon(cross, before, $size: 1.5rem);

			&:hover {
				color: var(--color-link-primary-hover);
			}
		}
	}
}

.ms-nav--desktop {
	display: flex;
	justify-content: flex-start;

	.ms-nav {

		// First level for desktop view as horizontal navigation item list
		&__menu {
			>ul {
				list-style: none;
				padding: 0;
				display: flex;
				align-items: center;

				>li {
					height: $header-height;
					display: flex;
					align-items: center;
					transition: transform 0.3s ease, opacity 0.3s ease 0.1s;

					button,
					span,
					a {
						@include font-navigation-desktop-horizontal;
						color: var(--color-text-primary);
						background: var(--color-bg-primary);
						padding: 0 var(--spacer-3);
						display: flex;
						align-items: center;
						height: 100%;
						white-space: nowrap;
						border-bottom: 2px solid transparent; // compensate border on :hover state

						&.active {
							background: var(--color-bg-tertiary);
							border-bottom: 2px solid var(--color-btn-primary-bg);
							color: var(--color-btn-primary-bg);
						}

						&:hover {
							background: var(--color-bg-tertiary);
							border-color: var(--color-btn-primary-bg);
							color: var(--color-btn-primary-bg);
						}
					}

					// Add icon for indicate submenu
					button {
						@include add-icon(chevron, after, $size: 0.8rem, $transform: rotate(90deg));

						@include media-breakpoint-up(lg) {
							@include add-icon(chevron, after, $size: 1rem, $transform: rotate(90deg));
						}

						&::after {
							margin-left: var(--spacer-2);
						}
					}
				}
			}
		}

		// Container element holds all sub-items
		&__layer {
			min-height: 30vh;
			position: absolute;
			top: $header-height;
			left: 0;
			width: 100%;
			padding: var(--spacer-4);
			background: var(--color-bg-tertiary);
		}

		// Sub levels: 2 and 3
		&__disable-top-menu {
			background: var(--color-bg-tertiary);
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
			margin: 0 auto;
			max-width: var(--grid-container-max-width-xs);

			@include media-breakpoint-up(xl) {
				max-width: var(--grid-container-max-width-xl);
			}

			@include font-navigation-desktop-sublevels;

			>ul {
				display: flex;
				justify-content: start;
				outline: none;
				position: relative;
				width: 100%;
				padding-left: 0;

				>li {
					margin: 0 var(--spacer-4);

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}

					button,
					span,
					a {
						color: var(--color-text-primary);

						&:hover {
							color: var(--color-btn-primary-bg);
						}
					}
				}
			}
		}

		// 3rd level
		&__default {
			background: var(--color-bg-tertiary);
			position: absolute;
			top: 100%;
			padding-top: var(--spacer-3);

			ul {
				display: flex;
				flex-direction: column;
				position: relative;
				width: 100%;

				li {

					span,
					a {
						color: var(--color-text-primary);
						text-transform: none;
					}
				}
			}
		}
	}
}

// Root entry element from module
.ms-nav--mobile.ms-nav {
	grid-area: position-start;
}

.ms-nav--mobile .ms-nav {

	// <button> element
	&__hamburger {
		display: inline-grid;
		place-items: center;
		width: 48px;
		height: 48px;
		transition-timing-function: linear;
		transition-duration: 0.15s;
		font: inherit;
		display: inline-block;
		overflow: visible;
		margin: 0;
		cursor: pointer;
		text-transform: none;
		color: inherit;
		border: 0;
		background-color: transparent !important;
		box-shadow: none;

		&:hover {
			background-color: transparent;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	&__hamburger-box {
		position: relative;
		display: inline-block;
		width: 25px;

		.ms-nav__hamburger-inner {
			width: 100%;
			height: 2px;
			position: absolute;
			inset: 0;
			background-color: var(--color-bg-secondary);

			&::before {
				background-color: var(--color-bg-secondary);
				position: absolute;
				width: 25px;
				height: 2px;
				display: block;
				content: '';
				top: -7px;
			}

			&::after {
				position: absolute;
				width: 25px;
				height: 2px;
				display: block;
				content: '';
				top: -14px;
				transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear;
				background-color: var(--color-bg-secondary);
			}
		}
	}

	&__layer {
		position: absolute;
		top: $header-height;
		left: 0;
		right: 0;
		padding: var(--spacer-4);
		background: var(--color-bg-tertiary);
	}

	&.parent,
	&.child {
		@include font-navigation-mobile;

		ul {
			li {
				button {
					display: flex;
					width: 100%;
					justify-content: flex-start;
					align-items: center;
				}
			}
		}
	}

	// Selected navigation item, acts as "headline" for current sub level list and as container for "back" link
	&__list__mobile__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: var(--spacer-2);
		margin-bottom: var(--spacer-2);
	}

	// Back button to navigation to previous level
	&__list__mobile__container__button {
		// Remove global button mixin styles from all navigation buttons
		margin-right: var(--spacer-2);
		@include add-icon(chevron, before, $size: 1rem, $transform: rotate(180deg));

		@if $enable-shadows {
			box-shadow: none;
		}

		&:hover {
			color: var(--color-navigation-mobile-text-hover);
		}
	}
}
