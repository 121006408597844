.msc-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Used for full-width alert messages
    &__container {
        margin-top: var(--spacer-4);
    }

    &__message {
        @include add-icon(warning, before, $size: var(--icon-size));

        &::before {
            margin-right: var(--spacer-2);
        }
    }
    &__explanation {
        margin-bottom: var(--spacer-4);
    }
}
