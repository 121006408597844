@import '../01-vendors/variables-custom';
@import '../03-mixins/icon';

//
// Extension of Basic Bootstrap alert
//

.alert {
    margin-top: $alert-margin-top;

    &-close {
        @include add-icon(cross, before, $size: 1rem);

        &::before {
            margin-right: var(--spacer-2);
        }
    }
}
