@import '../../03-mixins/icon.scss';
@import '../../03-mixins/typography';
@import '../../03-mixins/container';
@import 'bootstrap/scss/mixins/breakpoints';

.ms-accordion {
    margin-top: var(--module-margin-top);
    margin-bottom: var(--module-margin-bottom);
    @include container-layout-fluid;

    .ms-accordion-header-section__heading {
        margin-bottom: 40px;
    }

    .ms-accordion-item {
        background: none;
        display: block;
        margin: 0;
        position: relative;
        text-align: left;
        width: 100%;
        outline: none;
        border-top: 2px solid var(--color-bg-tertiary);
        cursor: pointer;

        .drawer {
            &__button {
                padding: var(--spacer-4) var(--spacer-4) var(--spacer-4) 0;
                position: relative;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                @include add-icon(chevron, after, $size: 1rem, $transform: rotate(90deg));

                .drawer__buttontext {
                    @include font-text;
                    font-weight: 700;
                    text-transform: uppercase;

                    @include media-breakpoint-down(sm) {
                        text-align: left;
                    }
                }

                &::after {
                    transition: transform 0.3s ease;
                    transform: rotate(90deg);
                    background: var(--color-link-primary);
                }

                &[aria-expanded='true']::after {
                    transform: rotate(270deg);
                }
            }
        }

        .ms-accordion-item-content {
            .ms-text-block {
                margin: 0;
                padding-bottom: var(--spacer-3);

                > * {
                    margin: 0;
                }
            }
        }
    }
}
