.msc-form {
    &__group {
        margin-bottom: var(--spacer-4);
    }

    &__check {
        position: relative;
        display: block;
        margin-bottom: var(--spacer-2);
    }

    &__radio {
        position: relative;
        display: block;
        margin-bottom: var(--spacer-2);
    }

    &__input-group {
        position: relative;
        display: block;
        margin-bottom: var(--spacer-2);
        @include input-group;
    }

    &__button-group {
        position: relative;
        display: flex;
        flex-flow: row;
        margin-bottom: var(--spacer-2);

        a,
        button {
            margin: 0 var(--spacer-2);

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
