@import '../01-vendors/variables-custom';
@import '../02-settings/configuration';
@import 'bootstrap/scss/mixins/breakpoints';

:root {
    --spacer-0: 0;
    --spacer-1: calc(#{map-get($spacers, 1)}* #{$responsive-scale-factor});
    --spacer-2: calc(#{map-get($spacers, 2)}* #{$responsive-scale-factor});
    --spacer-3: calc(#{map-get($spacers, 3)}* #{$responsive-scale-factor});
    --spacer-4: calc(#{map-get($spacers, 4)} * #{$responsive-scale-factor});
    --spacer-5: calc(#{map-get($spacers, 5)}* #{$responsive-scale-factor});
    --spacer-6: calc(#{map-get($spacers, 6)}* #{$responsive-scale-factor});
    --spacer-7: calc(#{map-get($spacers, 7)}* #{$responsive-scale-factor});
    --spacer-8: calc(#{map-get($spacers, 8)}* #{$responsive-scale-factor});

    @include media-breakpoint-up(lg) {
        --spacer-1: #{map-get($spacers, 1)};
        --spacer-2: #{map-get($spacers, 2)};
        --spacer-3: #{map-get($spacers, 3)};
        --spacer-4: #{map-get($spacers, 4)};
        --spacer-5: #{map-get($spacers, 5)};
        --spacer-6: #{map-get($spacers, 6)};
        --spacer-7: #{map-get($spacers, 7)};
        --spacer-8: #{map-get($spacers, 8)};
    }
}
