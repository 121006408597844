@import '../../01-vendors/variables-custom';
@import '../../03-mixins/icon';
@import '../../03-mixins/typography';

.ms-order-history {
    margin-top: var(--module-margin-top);
    margin-bottom: var(--module-margin-bottom);

    &__heading {
        margin: var(--spacer-4) 0;
    }

    &__total-entries {
        margin-top: var(--spacer-4);
        margin-bottom: var(--spacer-4);
        font-size: var(--font-size-h4);
        line-height: var(--font-line-height-h4);
    }

    &__search-title,
    &__filter-title {
        &::before {
            margin-right: var(--spacer-2);
        }
    }

    &__search-title {
        @include add-icon(search, before, $size: 1.5rem);
    }

    &__filter-title {
        @include add-icon(filter, before, $size: 1.5rem);
    }

    &__active-filter {
        display: block;
        margin-top: var(--spacer-2);
        margin-bottom: var(--spacer-2);

        &-container {
            display: flex;
            flex-wrap: wrap;
            gap: var(--spacer-1);
        }

        &-item {
            @include font-button;
            display: inline-flex;
            padding: var(--spacer-2) var(--spacer-4);
            color: var(--color-btn-secondary-text);
            border: $btn-border-width solid var(--color-btn-secondary-border);
            border-radius: valid-radius($border-radius);
            cursor: pointer;

            button {
                min-height: auto; // reset global styles
            }

            &-remove-button {
                @include add-icon(cross, before, $size: 1rem);

                &::before {
                    margin-left: var(--spacer-2);
                }
            }
        }

        &-label {
            @include label;
        }
    }

    &__active-filters-heading {
        @include label;
        margin-bottom: var(--spacer-2);
    }

    &__filter-divider {
        border-bottom: $border-width / 2 solid var(--color-bg-secondary);
        margin-top: var(--spacer-4);
        margin-bottom: var(--spacer-4);
        margin-left: calc(var(--spacer-4) * -1);
        margin-right: calc(var(--spacer-4) * -1);
    }

    // Button to reset active filter(s)
    &__filter-reset {
        position: absolute;
        right: var(--spacer-3);
        top: var(--spacer-2);
        @include label;
        color: var(--color-link-primary);
    }

    &__table-collapse {
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;

        &::after {
            margin-left: var(--spacer-2);
        }

        &[data-open='true'] {
            @include add-icon(chevron, after, $size: 1rem, $transform: rotate(-90deg));
        }

        &[data-open='false'] {
            @include add-icon(chevron, after, $size: 1rem, $transform: rotate(90deg));
        }
    }

    &__table-orderlines {
        margin-left: var(--spacer-5);

        .table {
            margin-bottom: var(--spacer-2);
        }
    }

    &__documents-link {
        @include add-icon(download, before, $size: 1rem);
        display: flex;
        align-items: center;
        margin-bottom: var(--spacer-2);

        &:before {
            margin-right: var(--spacer-2);
        }
    }

    &__highlight-search-term {
        background-color: var(--color-warning);
    }

    &__block-item {
        white-space: nowrap;
    }

    &__alert-heading {
        @include add-icon(info, before, $size: 1rem);

        &::before {
            margin-right: var(--spacer-2);
        }
    }

    &__alert-message {
        margin-bottom: var(--spacer-4);
    }

    .msc-section-box {
        position: relative;

        .msc-form__group:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-between(sm, lg) {
            .msc-form__group.col-sm {
                margin-bottom: 0;
            }
        }
    }
}
