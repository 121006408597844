@import 'bootstrap/scss/mixins/grid-framework';
@import 'bootstrap/scss/mixins/breakpoints';
@import '../../01-vendors/variables-custom';

// Hint: MS not using BEM elements :-(
// TODO: Add typography and states for different states
.ms-breadcrumb {
    color: var(--color-text-secondary-inverted);
    background: var(--color-bg-secondary);
    padding-bottom: var(--spacer-3);
    margin: 0 auto;
    width: 100%;

    @include media-breakpoint-up(xl) {
        max-width: var(--grid-container-max-width-xl);
    }

    &_list {
        width: 100%;
        list-style: none;
        display: inline-flex;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }

    // &_item {}

    li {
        // Divider between items
        > span {
            padding-left: var(--spacer-2);
            padding-right: var(--spacer-2);
        }

        a {
            color: var(--color-text-primary-inverted);
            border-bottom: $border-width / 2 solid var(--color-text-primary-inverted);
        }
    }
}
