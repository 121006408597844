// Most of the styles are borrowed from original Bootstrap modal: https://github.com/twbs/bootstrap/blob/v4.6.2/scss/_modal.scss
// Microsoft used the BS implementation/classes "somehow" but not exactly for the core modal component
// We decided to use the core styles from BS but modifed/cleanuped them for the MS implementation

@import '../../01-vendors/variables-custom';
@import '../../02-settings/configuration';
@import '../../03-mixins/typography';
@import '../../03-mixins/icon';

// .modal-open           - body class for killing the scroll
// .msc-modal            - container to scroll within
// .msc-modal__dialog    - positioning shell for the actual modal
// .msc-modal__content   - actual modal w/ bg and corners and stuff

.modal-open {
    // Kill the scroll on the body
    overflow: hidden;

    .msc-modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Container that the modal scrolls within
.msc-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695

    // Shell div to position the modal with bottom padding
    &__dialog {
        position: relative;
        width: auto;
        margin: $modal-dialog-margin;
        // allow clicks to pass through for custom click handling to close modal
        pointer-events: none;

        // Scale up the modal
        @include media-breakpoint-up(sm) {
            // Automatically set modal's width for larger viewports
            max-width: $modal-md;
            margin: $modal-dialog-margin-y-sm-up auto;
        }

        // When fading in the modal, animate it to slide down
        .msc-modal.fade & {
            @include transition($modal-transition);
            transform: $modal-fade-transform;
        }
        .msc-modal.show & {
            transform: $modal-show-transform;
        }
    }

    // Scale up the modal
    @include media-breakpoint-up(sm) {
        &__dialog-sm {
            max-width: $modal-sm;
        }
    }

    @include media-breakpoint-up(lg) {
        &__dialog-lg,
        &__dialog-xl {
            max-width: $modal-lg;
        }
    }

    @include media-breakpoint-up(xl) {
        &__dialog-xl {
            max-width: $modal-xl;
        }
    }

    // Actual modal
    &__content {
        position: relative;
        margin-top: $header-height;
        display: flex;
        flex-direction: column;
        width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
        // counteract the pointer-events: none; in the .modal-dialog
        color: $modal-content-color;
        pointer-events: auto;
        background-color: $modal-content-bg;
        background-clip: padding-box;
        border: $modal-content-border-width solid $modal-content-border-color;
        @include box-shadow($modal-content-box-shadow-xs);
        // Remove focus outline from opened modal
        outline: 0;
    }

    // Modal background
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $zindex-modal-backdrop;
        width: 100vw;
        height: 100vh;
        background-color: $modal-backdrop-bg;

        // Fade for backdrop
        &.fade {
            opacity: 0;
        }
        &.show {
            opacity: $modal-backdrop-opacity;
        }
    }

    // Modal header
    // Top section of the modal w/ title and dismiss
    &__header {
        display: flex;
        align-items: flex-start; // so the close btn always stays on the upper right corner
        justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
        padding: $modal-header-padding;
        @include border-top-radius($modal-content-inner-border-radius);
    }

    &__title {
        @include font-heading-h2;
    }

    // Modal body
    // Where all modal content resides (sibling of .msc-modal__header and .msc-modal__footer)
    &__body {
        position: relative;
        // Enable `flex-grow: 1` so that the body take up as much space as possible
        // when there should be a fixed height on `.modal-dialog`.
        flex: 1 1 auto;
        padding: $modal-inner-padding;
    }

    // Footer (for actions)
    &__footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center; // vertically center
        justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    }

    &__close-button {
        position: absolute;
        right: var(--spacer-2);
        top: var(--spacer-2);
        min-height: auto; // Reset global custom button style
        @include add-icon(cross, before, $size: 1.5rem);

        &:hover {
            color: var(--color-link-primary-hover);
        }
    }
}
