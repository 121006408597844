@import '../01-vendors/variables-custom';
@import '../02-settings/configuration';
@import '../03-mixins/icon';
@import '../03-mixins/typography';

@mixin legend {
    @include font-form-legend;
}

@mixin input-base {
    --size-icon: 1.15rem;
    margin: 0;
    font-family: inherit;
    @include font-size(inherit);
    color: var(--color-text-primary);
    border: $input-border-width solid var(--color-input-border);
    background-color: var(--color-input-bg);

    &:focus-visible,
    &:focus:required:invalid {
        /* TODO: Confirm focus state with lxs (needed for usability/a11y) */
        border: $input-border-width solid var(--color-input-checked);
        outline: none;
    }
}

@mixin input-text {
    @include input-base;
    padding: var(--spacer-2) var(--spacer-1);
    line-height: var(--font-line-height-input);
    width: 100%;

    ::placeholder {
        color: var(--color-input-placeholder);
    }
}

@mixin input {

    /* SCSS ERROR: :is(:not(...)) should be the same as only :not(...) - but the latter fails */
    /* &:not([type="radio"],[type="checkbox"]) { */
    &:is(:not([type='radio'], [type='checkbox'])) {
        @include input-text;
    }

    &[type='search'] {
        &::-webkit-search-cancel-button {
            @include add-background-icon(search, center right, $size: var(--size-icon));
            opacity: 1 !important;
            -webkit-appearance: none;
            appearance: none;
            height: var(--font-line-height-input);
            width: var(--font-line-height-input);
        }

        &[value]:not([value=''])::-webkit-search-cancel-button {
            @include add-background-icon(cross, center right, $size: var(--size-icon));
        }

        &::-webkit-search-decoration {
            -webkit-appearance: none;
        }
    }

    &[type='date'] {
        &::-webkit-calendar-picker-indicator {
            @include add-background-icon(calendar, $size: var(--size-icon));
        }

        &::-webkit-datetime-edit-fields-wrapper {
            padding: 0;
        }
    }

    &:is([type='radio'], [type='checkbox']) {
        @include input-base;
        box-sizing: border-box;
        appearance: none;
        -webkit-appearance: none;
        vertical-align: sub;
        margin-right: var(--spacer-2);
        height: var(--spacer-4);
        width: var(--spacer-4);
        padding: 0;
    }

    &[type='radio'] {
        border-radius: 50%;

        &:checked {
            background: radial-gradient(1q, var(--color-input-checked), var(--spacer-2), var(--color-input-bg));
        }
    }

    &[type='checkbox'] {
        &:checked {
            background: var(--color-input-checked);
            border: var(--color-input-checked);
            @include add-background-icon(check-negative);
        }
    }
}

@mixin dropdown {
    @include input-text;
    appearance: none;
    -webkit-appearance: none;
    @include add-background-icon(chevron-down, top 65% right 7px, calc(var(--size-icon) * #{$responsive-scale-factor}));

    @include media-breakpoint-up(lg) {
        @include add-background-icon(chevron-down, top 65% right 7px, var(--size-icon));
    }

    text-transform: none;
    word-wrap: normal;
    padding-right: 30px; // Magic value: pixel dimension + some addional spacing to avoid overlapping of icon and text
}

@mixin label {
    @include font-button;
    display: block;
    margin-bottom: 0;
}

@mixin textarea {
    @include input-text;
    overflow: auto;
    resize: vertical;
}

@mixin quantity {
    --height: 47px;
    display: flex;

    .quantity-input {
        @include input-text;
        border-radius: 0;
        height: var(--height);
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button {
            appearance: none;
            -webkit-appearance: none;
        }
    }

    .quantity__controls {
        --color-icon: var(--color-text-primary);
        @include input-text;
        border-radius: 0;
        max-width: var(--height);
        height: var(--height);

        &:disabled {
            --color-icon: var(--color-text-secondary);
        }

        &.decrement {
            @include add-icon(minus, before, var(--color-icon), 1rem);
            border-right: 0;

            &:before {
                -webkit-mask-position: 2px center;
                mask-position: 2px center;
            }
        }

        &.increment {
            @include add-icon(plus, before, var(--color-icon), 1rem);
            border-left: 0;

            &:before {
                -webkit-mask-position: 2px center;
                mask-position: 2px center;
            }
        }
    }
}

@mixin input-group {
    .msc-form__input-group-container {
        display: flex;

        input:is(:not([type='radio']):not([type='checkbox']):not([type='date'])) {
            border-right: 0;
            flex: 1 1 auto;
        }
    }

    .msc-form__input-group-append {
        @include input-text;
        border-color: var(--color-input-border);
        border-style: solid;
        border-width: $input-border-width $input-border-width $input-border-width 0;
        width: auto;
        display: flex;
        padding-left: var(--spacer-2);
        padding-right: var(--spacer-2);
        background: var(--color-bg-tertiary);
    }

    .msc-form__select-group-append {
        @include input-text;
        width: auto;
        display: flex;
        padding: 0;
        border-left: none;

        .msc-form__select-group-append-select {
            width: auto;
            background-color: var(--color-bg-tertiary);
            border: none;
            padding-left: var(--spacer-2);
        }
    }


}