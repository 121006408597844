@import '../../03-mixins/typography';

.msc-process-bar {
    $process-bar-height: 60px;
    $process-bar-polygon-width-compensation: 25px;

    margin-bottom: var(--spacer-4);
    width: calc(100% - #{$process-bar-polygon-width-compensation});

    &__list {
        display: flex;
        padding-left: 0;
        counter-reset: msc-process-bar-item;
        @include media-breakpoint-down(md) {
            counter-reset: none;
        }
    }

    &__item {
        &:not(.msc-process-bar__item--is-active) {
            overflow: hidden;
        }

        position: relative;
        height: $process-bar-height;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-text-secondary);
        background-color: var(--color-bg-tertiary);
        margin-right: -$process-bar-polygon-width-compensation; // space between polygons
        clip-path: polygon(calc(100% - 30px) 0%, 100% 50%, calc(100% - 30px) 100%, 0% 100%, 30px 50%, 0% 0%);
        counter-increment: msc-process-bar-item;
        flex: 1;
        @include font-process-bar();
        cursor: pointer;

        &::before {
            content: counter(msc-process-bar-item) '.';
            padding-right: var(--spacer-2);
        }

        &:first-child {
            clip-path: polygon(calc(100% - 30px) 0%, 100% 50%, calc(100% - 30px) 100%, 0% 100%, 0px 50%, 0% 0%);
        }

        @include media-breakpoint-up(lg) {
            padding: 0 var(--spacer-5);
            justify-content: flex-start;
        }

        span {
            &:not(.msc-process-bar__item--is-active) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &__item--is-active {
        color: var(--color-text-primary-inverted);
        background-color: var(--color-btn-primary-bg);

        /* SELECT ALL ITEMS AFTER CURRENTLY SELECTED ITEM */
        & ~ .msc-process-bar__item {
            cursor: initial;
        }
    }
}
