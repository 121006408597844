@import 'bootstrap/scss/mixins/breakpoints';
@import '../../01-vendors/variables-custom';
@import '../../02-settings/configuration';
@import '../../03-mixins/icon';
@import '../../03-mixins/typography';

.ms-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $header-height;
	align-items: center;
	transition: background-color 0.3s ease, padding-bottom 0.3s ease, box-shadow 0.2s;
	transition-delay: 0.2s;
	background: var(--color-bg-primary);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1);
	z-index: $zindex-header;

	&__container {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
		margin: 0 auto;
		max-width: var(--grid-container-max-width-xs);

		@include media-breakpoint-up(xl) {
			max-width: var(--grid-container-max-width-xl);
		}
	}

	&__topbar {
		display: grid;
		grid-template-areas: 'position-start';
		grid-template-columns: auto 1fr;
		grid-auto-flow: column;
		grid-column-gap: 0;
		align-items: center;
		height: $header-height;

		.ms-cart-icon {
			.msc-btn {
				@include button-primary();
				@include add-icon(cart, $size: 1rem);
				display: flex;

				&::before {
					margin-right: var(--spacer-2);
				}

				.msc-cart-icon__count {
					visibility: visible !important;
				}
			}
		}
	}

	&__logo {
		@include media-breakpoint-up(lg) {
			grid-area: position-start;
			margin-right: var(--spacer-6);
		}

		img {
			max-width: 112px;
			height: auto;
		}
	}

	// Main container holding sign button OR profile name
	&__account-info {
		margin-right: var(--spacer-3);

		>a,
		>button {
			@include font-account-info;
			color: var(--color-account-info-text);

			&:hover {
				color: var(--color-account-info-text-hover);
			}
		}
	}

	// Layer with account navigation items
	&__account-info-content {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1);
		background: var(--color-bg-primary);
		padding: var(--spacer-2) var(--spacer-4);
		margin-top: var(--spacer-2);
		@include font-account-info-layer;

		a {
			color: var(--color-account-info-text);

			&:hover {
				color: var(--color-account-info-text-hover);
			}
		}
	}

	&__profile-button {
		display: flex;
		align-items: center;
		@include add-icon(chevron, after, $size: 0.8rem, $transform: rotate(90deg));

		@include media-breakpoint-up(lg) {
			@include add-icon(chevron, after, $size: 1rem, $transform: rotate(90deg));
		}

		@include media-breakpoint-down(sm) {
			.ms-profile-button-text {
				@include add-icon(user, $size: 1rem);

				&>div {
					font-size: 0;
					color: transparent;
					position: absolute;
				}
			}
		}

		&::after {
			margin-left: var(--spacer-2);
		}
	}

	&__signin-button {
		display: flex;
		align-items: center;
		@include add-icon(user, after, $size: 1rem);

		&::after {
			margin-left: var(--spacer-2);
		}
	}

	// On small screen show only icon
	&__signin-button-text {
		display: none;

		@include media-breakpoint-up(sm) {
			display: block;
		}
	}

	&__divider {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.cip-cta-link {
		max-width: fit-content;

		&__text {
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			&:after {
				margin-left: 0;
			}
		}
	}
}
