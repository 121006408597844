$filePath: '../../../master-assets/svgs/icon-sprite.svg';

@mixin add-icon($icon, $selector: before, $background: currentColor, $size: 0.75em, $transform: null) {
    &:#{$selector} {
        -webkit-mask-image: url('#{$filePath}##{$icon}');
        mask-image: url('#{$filePath}##{$icon}');
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        background: #{$background};
        display: inline-block;
        height: #{$size};
        aspect-ratio: 1;
        content: '';

        @if $transform {
            transform: $transform;
        }
    }
}

@mixin add-background-icon($icon, $position: center, $size: 0.75em, $repeat: false) {
    background-image: url('#{$filePath}##{$icon}');
    background-position: #{$position};
    background-size: $size;

    @if $repeat == false {
        background-repeat: no-repeat;
    }
}
