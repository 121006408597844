@import 'bootstrap/scss/mixins/grid';
@import '../../03-mixins/rich-text-styles';
@import '../../03-mixins/container';

.ms-text-block {
    margin-top: var(--module-margin-top);
    margin-bottom: var(--module-margin-bottom);
    @include container-layout-fluid;

    @include rich-text-styles;

    &.modulewidth__small,
    &.modulewidth__medium,
    &.modulewidth__large {
        margin-left: auto;
        margin-right: auto;
    }

    &.modulewidth__small {
        @include make-col(4);
    }

    &.modulewidth__medium {
        @include make-col(6);
    }

    &.modulewidth__large {
        @include make-col(8);
    }

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

}