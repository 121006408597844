@import '../../01-vendors/variables-custom';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/screen-reader';
@import '../../03-mixins/buttons';
@import '../../03-mixins/icon';

.msc-carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: var(--module-margin-bottom);

    &__item {
        display: none;

        &.active {
            display: block;
        }
    }

    &__control__prev,
    &__control__next {
        @include button-primary();
        position: absolute;
        bottom: var(--spacer-3);
        width: var(--btn-circle);
        height: var(--btn-circle);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .screen-reader-only {
            @include sr-only;
        }
    }

    &__control__prev {
        right: calc(var(--btn-circle) + var(--btn-circle));
        @include add-icon(chevron, before, $size: 1rem, $transform: rotate(180deg));
    }

    &__control__next {
        right: var(--spacer-3);
        @include add-icon(chevron, before, $size: 1rem);
    }

    &__indicators {
        display: none;
    }

    &__dismiss {
        position: absolute;
        right: var(--spacer-3);
        top: 0; // top position is "definied" by btn-min-height
        color: var(--color-text-secondary-inverted);
        @include add-icon(cross, before, $size: 1rem, $transform: rotate(180deg));
    }

    // Specific styles for content-block module when inside of carousel
    .ms-content-block {
        margin-bottom: 0; // Reset module margin
    }
}

[data-m-layout='tile'] .msc-carousel {
    // Specific styles for content-block module when inside of carousel with "tile" layout
    [data-m-layout='tile-1-1'].ms-content-block,
    [data-m-layout='tile-3-2'].ms-content-block {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0; // Reset module margin
    }

    &__inner {
        white-space: nowrap;
        margin-right: calc(var(--spacer-2) * -1);
        margin-left: calc(var(--spacer-2) * -1);
    }

    &__item {
        width: 100%;
        display: inline-flex;
        padding-left: var(--spacer-2);
        padding-right: var(--spacer-2);

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 25%;
        }

        // 100 View
        // Select all next siblings
        @include media-breakpoint-only(xs) {
            &.active ~ .msc-carousel__item {
                display: none;
            }

            // Select all preceding siblings
            &:has(~ .active) {
                display: none;
            }
        }

        // 50-50 View
        @include media-breakpoint-only(sm) {
            &.active ~ .msc-carousel__item:nth-of-type(1) {
                display: none;
            }

            // 1. Handling for first/last step
            // 2. Select the 2nd preceding siblings
            &:has(+ .active:not(:last-of-type)),
            &:has(~ * + .active) {
                display: none;
            }
        }

        // 25-25-25-25 View
        @include media-breakpoint-up(lg) {
            &.active ~ .msc-carousel__item:nth-of-type(1) {
                display: none;
            }

            // 1. Handling for first/last step(s)
            // 2. Select the 3nd preceding siblings
            &:has(~ .active:not(:last-of-type):not(:nth-last-of-type(2)):not(:nth-last-of-type(3))),
            &:has(~ * + * + * + .active) {
                display: none;
            }
        }
    }

    // Pagination styles for carousel with "tile" layout
    &__control__prev,
    &__control__next {
        top: calc(50% - var(--btn-circle) / 2);
        color: var(--color-link-primary);
        background-color: var(--color-text-secondary-inverted);

        &:hover {
            color: var(--color-link-primary-hover);
        }
    }

    &__control__prev {
        left: var(--spacer-3);
    }
}
