@import 'bootstrap/scss/mixins/breakpoints';
@import '../../01-vendors/variables-custom';
@import '../../03-mixins/typography';

.ms-site-picker {
    margin-right: var(--spacer-3);

    &__container {
        display: flex;
        align-items: center;
    }

    &__button,
    .drawer__button {
        @include font-site-picker;

        @if $enable-shadows {
            box-shadow: none;
        }

        color: var(--color-site-picker-text);
        display: flex;
        align-items: center;

        @include add-icon(chevron, after, $size: 0.8rem, $transform: rotate(90deg));

        @include media-breakpoint-up(lg) {
            @include add-icon(chevron, after, $size: 1rem, $transform: rotate(90deg));
        }

        @include media-breakpoint-down(sm) {
            .drawer__buttontext {
                @include add-icon(globe, after, $size: 1rem);

                &::after {
                    margin-left: var(--spacer-2);
                }
            }

            &text {
                display: flex;
            }
        }

        &::after {
            margin-left: var(--spacer-2);
        }

        &:hover {
            background: transparent;
            color: var(--color-site-picker-text-hover);
        }
    }

    // Layer with site-picker items
    &__drawer > :is(.collapse, .collapsing),
    &__list {
        position: absolute;
        z-index: 1; // Reset stack
        list-style: none;
        background: var(--color-bg-primary);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1);
        padding: var(--spacer-2) var(--spacer-4);
        margin-top: var(--spacer-2);
        @include font-site-picker-layer;

        a {
            color: var(--color-site-picker-text);
            transition: all 0.3s linear;

            &:hover {
                color: var(--color-site-picker-text-hover);
            }
        }
    }
}
