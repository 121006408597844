//
// Extension of Basic Bootstrap table
//

.table {
	td {
		@include font-table-body;
	}

	td,
	th {
		vertical-align: middle;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	th {
		border-top: 0;
		vertical-align: baseline;
	}

	thead th {
		@include font-table-header;
		white-space: nowrap;
	}

	// Can be applied on td or tr: use-case override global table padding styles
	.no-padding {
		padding: 0;
	}
}