.msc-section-box {
    background: var(--color-bg-tertiary);
    padding: var(--spacer-4);

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: var(--spacer-4);
        word-break: break-word;
        hyphens: auto;
    }

    &:first-of-type {
        margin-bottom: var(--spacer-4);
    }
}
