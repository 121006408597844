@import 'bootstrap/scss/variables';
@import '../02-settings/color';
@import '../02-settings/configuration';

// Please add your custom variable overrided below

// Body
//
// Settings for the `<body>` element.

$body-bg: var(--color-bg-primary);
$body-color: var(--color-text-primary);

// Links
//
// Style anchor elements.

$link-color: var(--color-link-primary);
$link-decoration: $link-primary-decoration;
$link-hover-color: var(--color-link-primary-hover);
$link-hover-decoration: $link-primary-hover-decoration;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 0;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: var(--font-family-sans-serif);
$font-family-monospace: var(--font-family-monospace);
$font-family-base: $font-family-sans-serif;

$font-size-base: var(--font-size-base); // Assumes the browser default, typically `16px`
$font-weight-normal: 400;
$font-weight-bold: 700;

$line-height-base: var(--font-line-height-base);

$headings-margin-bottom: 0;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.5rem;
$table-cell-padding-sm: 0.25rem;

// $table-color: $body-color !default;
// $table-bg: null !default;
// $table-accent-bg: rgba($black, 0.05) !default;
// $table-hover-color: $table-color !default;
// $table-hover-bg: rgba($black, 0.075) !default;
// $table-active-bg: $table-hover-bg !default;

// $table-border-width: $border-width !default;
$table-border-color: $border-color;

// $table-head-bg: $gray-200 !default;
$table-head-color: $color-text-primary;
$table-th-font-weight: var(--font-weight-bold);

// $table-dark-color: $white !default;
// $table-dark-bg: $gray-800 !default;
// $table-dark-accent-bg: rgba($white, 0.05) !default;
// $table-dark-hover-color: $table-dark-color !default;
// $table-dark-hover-bg: rgba($white, 0.075) !default;
// $table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

// $table-striped-order: odd !default;

// $table-caption-color: $text-muted !default;

// $table-bg-level: -9 !default;
// $table-border-level: -6 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 100%,
    xl: 1920px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 40px;

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 2px;
$border-color: var(--color-table-border-color);
$border-radius: 70px;
$border-radius-sm: 0;
$border-radius-lg: 0;

$box-shadow: 0 4px 4px 0 rgba($color-text-primary, 0.25);
$box-shadow-sm: null;
$box-shadow-lg: null;

// Buttons
$btn-border-width: $border-width;

// Forms
$input-border-width: $border-width / 2;

// Cards
$card-border-color: null;
$card-border-width: $border-width;

// Alerts
$alert-padding-y: map-get($spacers, 4); // --spacer-4
$alert-padding-x: map-get($spacers, 4); // --spacer-4
$alert-margin-top: var(--spacer-2); // Custom variable / property
$alert-margin-bottom: var(--spacer-2);
$alert-border-radius: 0;
$alert-link-font-weight: var(--font-weight-normal);
$alert-border-width: 0;

// $alert-bg-level: -10 !default;
// $alert-border-level: -9 !default;
// $alert-color-level: 6 !default;

// Toasts
$toast-color: inherit;
$toast-background-color: transparent;
$toast-border-width: 0;
$toast-border-color: transparent;
$toast-border-radius: 0;
$toast-box-shadow: none;

$toast-header-color: inherit;
$toast-header-background-color: transparent;

// Modals
// More information/hints: src/themes/master/styles/modules/modal/modal.scss

// Padding applied to the modal body
$modal-inner-padding: var(--spacer-4);
$modal-dialog-margin-y-sm-up: var(--spacer-2);

$modal-dialog-margin: var(--spacer-2);
$modal-content-bg: rgba(255, 255, 255, 1);
$modal-content-border-color: rgba(0, 0, 0, 0.5);
$modal-content-border-width: $border-width;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;
$modal-content-box-shadow-xs: $box-shadow;

$modal-backdrop-bg: rgba(0, 0, 0, 1);
$modal-backdrop-opacity: 0.5;
$modal-header-padding-y: var(--spacer-2);
$modal-header-padding-x: var(--spacer-2);
// $modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-xl: 1140px !default;
// $modal-lg: 800px !default;
// $modal-md: 500px !default;
// $modal-sm: 300px !default;

// $modal-fade-transform: translate(0, -50px) !default;
// $modal-show-transform: none !default;
// $modal-transition: transform 0.3s ease-out !default;
// $modal-scale-transform: scale(1.02) !default;

// Tooltip
$tooltip-content-border-color: rgba(0, 0, 0, 0.5);
$tooltip-content-border-width: $border-width;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25
        ),
        2: (
            $spacer * 0.5
        ),
        3: $spacer,
        4: (
            $spacer * 1.5
        ),
        5: (
            $spacer * 3
        ),
        6: (
            $spacer * 6
        ),
        7: (
            $spacer * 12
        ),
        8: (
            $spacer * 24
        )
    ),
    $spacers
);

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-border-width: $border-width;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-back-to-top: 999; // Custom entry
$zindex-header: 1000; // Custom entry
$zindex-dropdown: 1010; // Modified entry
// $zindex-sticky: 1020;
// $zindex-fixed: 1030;
// $zindex-modal-backdrop: 1040;
// $zindex-modal: 1050;
// $zindex-popover: 1060;
// $zindex-tooltip: 1070;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: true;
$enable-shadows: true;
