@import '../01-vendors/variables-custom';

:root {
    --grid-xs: #{map-get($grid-breakpoints, 'xs')};
    --grid-sm: #{map-get($grid-breakpoints, 'sm')};
    --grid-md: #{map-get($grid-breakpoints, 'md')};
    --grid-lg: #{map-get($grid-breakpoints, 'lg')};
    --grid-xl: #{map-get($grid-breakpoints, 'xl')};
    --grid-container-max-width-xs: #{map-get($container-max-widths, 'xs')};
    --grid-container-max-width-sm: #{map-get($container-max-widths, 'sm')};
    --grid-container-max-width-md: #{map-get($container-max-widths, 'md')};
    --grid-container-max-width-lg: #{map-get($container-max-widths, 'lg')};
    --grid-container-max-width-xl: #{map-get($container-max-widths, 'xl')};
    --grid-gutter-columns: #{$grid-columns};
    --grid-gutter-width: #{$grid-gutter-width};
}
