@keyframes msc-skeleton {
    100% {
        transform: translateX(100%);
    }
}

.msc-skeleton {
    background-color: var(--color-bg-tertiary);
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;
    position: relative;
    user-select: none;
    overflow: hidden;
    &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(90deg, var(--color-bg-tertiary), var(--color-bg-primary), var(--color-bg-tertiary));
        transform: translateX(-100%);
        animation-name: msc-skeleton;
        animation-direction: normal;
        animation-duration: 1.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
}
