// Default MS image "component"
@import "../../03-mixins/image";

$default-image-width: 150px;
$default-empty-image-width: $default-image-width;
$default-loading-image-width: $default-image-width;
$default-image-height: 150px;
$default-empty-image-height: $default-image-height;
$default-loading-image-height: $default-image-height;

.msc-loading_image {
	@include image-loading-placeholder($default-loading-image-width, $default-loading-image-height);
}

.msc-empty_image {
	@include image-empty-placeholder($default-empty-image-width, $default-loading-image-height);
}

.msc-thumbnail_image {
	filter: blur(5px);
	transition: visibility 0ms ease 400ms;
}

.msc-image-container {
	display: grid;
	overflow: hidden;
	position: relative;
	z-index: 0;
	margin: auto;

	>* {
		grid-area: 1 / 1;
		grid-row: 1;
	}

	>:nth-child(1) {
		z-index: 0;
	}

	>:nth-child(2) {
		z-index: 1;
	}

	>:nth-child(3) {
		z-index: 2;
	}

	.msc-loading_image {
		&.hidden {
			visibility: hidden;
		}
	}

	.msc-thumbnail_image {
		&.loading {
			visibility: hidden;
		}

		&.hidden {
			visibility: hidden;
		}
	}

	.msc-main_image {
		&.loading {
			visibility: hidden;
		}

		&.hidden {
			visibility: hidden;
		}
	}

	.msc_image {
		object-fit: contain;
	}
}

// TODO: Rethink?
.msc_image {
	max-width: 100%;
	height: auto;
}
