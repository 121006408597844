@import '../01-vendors/variables-custom';
@import '../03-mixins/icon';
@import '../03-mixins/typography';

//
// Extension of Basic Bootstrap toast
//

$toast-bg-level: -1 !default;
$toast-border-level: -9 !default;
$toast-color-level: 6 !default;

.toast {
    opacity: 1 !important;

    &-header {
        justify-content: space-between;
        h4 {
            @include label;
        }
    }

    &-close {
        color: inherit;
        @include add-icon(cross, before, $size: 1rem);
        &::before {
            margin-right: var(--spacer-2);
        }
    }
    &-body {
        content: attr(data-text);
        white-space: pre-wrap;
    }
}

@each $color, $value in $theme-colors {
    .toast-#{$color} {
        @include alert-variant(
            theme-color-level($color, $toast-bg-level),
            theme-color-level($color, $toast-border-level),
            theme-color-level($color, $toast-color-level)
        );
        border: none;
        border-radius: 0;
    }
}
