@import 'bootstrap/scss/mixins/breakpoints';
@import '../../01-vendors/variables-custom';
@import '../../03-mixins/typography';

.ms-footer {
    &__container {
        border-bottom: $border-width solid var(--color-text-secondary);

        .ms-text-block p {
            @include font-heading-h1;

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;

                span {
                    &:last-child {
                        display: none;
                    }
                }
            }

            span {
                &:first-child {
                    color: var(--color-bg-primary) !important;
                }

                &:nth-child(2) {
                    color: var(--color-btn-primary-bg) !important;
                }

                &:last-child {
                    color: var(--color-bg-primary) !important;
                }
            }
        }
    }

    .ms-image-list {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: var(--spacer-4);
        display: flex;

        @include media-breakpoint-down(lg) {
            margin-top: var(--spacer-4);
        }

        h6 {
            @include font-footer;
            color: var(--color-text-secondary);
            white-space: nowrap;
            margin-left: var(--spacer-5);
        }

        img {
            max-width: 122px;
            height: auto;
        }

        ul {
            padding: 0;
            margin-bottom: 0;

            li {
                //it's necessary cause it will be overridden by line 97
                margin: 0 !important;
            }
        }
    }

    .default-container-2-slots {
        @include media-breakpoint-down(lg) {
            .row:first-child {
                flex-direction: column-reverse;
            }
        }

        max-width: 100% !important;
        margin-top: var(--spacer-3) !important;
    }

    // Headline of "Footer item" module
    &__heading__title {
        @include font-footer;
    }

    // Headline of "Footer category" module
    &__list {
        display: flex;
        list-style: none;
        justify-content: flex-start;
        flex-direction: column;

        padding: var(--spacer-0); // reset of global ul style

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            color: var(--color-link-secondary);
        }

        @include media-breakpoint-up(xl) {
            justify-content: end;
        }
    }

    &__list-item {
        display: flex;
        color: var(--color-text-secondary);
        margin-top: var(--spacer-3);
        margin-bottom: var(--spacer-3);

        &:last-child {
            margin: var(--spacer-0);
        }

        @include media-breakpoint-up(lg) {
            margin-top: var(--spacer-2);
            margin-bottom: var(--spacer-2);
            margin-left: var(--spacer-0);
            margin-right: var(--spacer-3);

            &::before {
                content: '';
                border-right: $border-width solid var(--color-text-secondary);
                margin-right: var(--spacer-3);
            }

            &:first-child {
                margin-left: var(--spacer-0);

                &::before {
                    border-right: none;
                    margin-right: var(--spacer-0);
                }
            }

            // last item of footer category module is filled with to-top module, it is positioned absolute so it must be visually not inside of the list
            // Styles for "real" and "visual" last list item
            &:last-child,
            &:nth-last-child(2) {
                margin-right: var(--spacer-0);
            }

            &:last-child {
                margin-right: var(--spacer-0);
                margin-left: var(--spacer-0);

                &::before {
                    border-right: none;
                    margin-right: var(--spacer-0);
                }
            }
        }
    }

    &__link {
        @include font-footer;
        margin: var(--spacer-2) 0;
        color: var(--color-link-secondary);

        &:hover {
            color: var(--color-link-secondary-hover);
        }
    }

    .ms-text-block {
        margin-top: var(--spacer-5);
        margin-bottom: var(--spacer-3);
    }
}
