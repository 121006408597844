@import '../01-vendors/variables-custom';
@import '../02-settings/configuration';
@import 'bootstrap/scss/mixins/breakpoints';

// Typography styles for usage inside of modules
@mixin font-heading-h1 {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-h1) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-h1) * #{$responsive-scale-factor});
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-h1);
        line-height: var(--font-line-height-h1);
    }
}

@mixin font-heading-h2 {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-h2) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-h2) * #{$responsive-scale-factor});

    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-h2);
        line-height: var(--font-line-height-h2);
    }
}

@mixin font-heading-h3 {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-h3) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-h3) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-h3);
        line-height: var(--font-line-height-h3);
    }
}

@mixin font-heading-h4 {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-h4) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-h4) * #{$responsive-scale-factor});

    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-h4);
        line-height: var(--font-line-height-h4);
    }
}

@mixin font-footer {
    font-family: var(--font-family-sans-serif);
    font-size: calc(var(--font-size-base) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-navigation-footer) * #{$responsive-scale-factor});
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-base);
        line-height: var(--font-line-height-navigation-footer);
    }
}

@mixin font-button {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-button) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-button) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-button);
        line-height: var(--font-line-height-button);
    }
}

@mixin font-pagination {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-pagination) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-pagination) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-pagination);
        line-height: var(--font-line-height-pagination);
    }
}

@mixin font-site-picker {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-site-picker) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-site-picker) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-site-picker);
        line-height: var(--font-line-height-site-picker);
    }

    @include font-site-picker-layer();
}

@mixin font-site-picker-layer {

    // Layer items with navigation items
    a {
        font-size: calc(var(--font-size-site-picker-layer) * #{$responsive-scale-factor});
        line-height: calc(var(--font-line-height-site-picker-layer) * #{$responsive-scale-factor});
        font-weight: var(--font-weight-normal);

        @include media-breakpoint-up(lg) {
            font-size: var(--font-size-site-picker-layer);
            line-height: var(--font-line-height-site-picker-layer);
        }
    }
}

@mixin font-account-info {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-account-info) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-account-info) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-account-info);
        line-height: var(--font-line-height-account-info);
    }
}

@mixin font-account-info-layer {

    // Layer items with navigation items
    a {
        font-family: var(--font-family-sans-serif);
        font-weight: var(--font-weight-normal);
        font-size: calc(var(--font-size-account-info-layer) * #{$responsive-scale-factor});
        line-height: calc(var(--font-line-height-account-info-layer) * #{$responsive-scale-factor});
        text-decoration: none;
        vertical-align: middle;
        transition: all 0.3s linear;

        @include media-breakpoint-up(lg) {
            font-size: var(--font-size-account-info-layer);
            line-height: var(--font-line-height-account-info-layer);
        }
    }
}

@mixin font-navigation-desktop-horizontal {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-navigation-main-1) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-navigation-main-1) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-navigation-main-1);
        line-height: var(--font-line-height-navigation-main-1);
    }
}

@mixin font-navigation-desktop-sublevels {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-navigation-main-1) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-navigation-main-1) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-navigation-main-1);
        line-height: var(--font-line-height-navigation-main-1);
    }

    // Remove global button mixin styles from all navigation buttons
    button {
        @if $enable-shadows {
            box-shadow: none;
        }
    }

    // 2nd level
    ul {
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        li {
            padding: 0;

            button,
            span,
            a {
                font-size: var(--font-size-navigation-main-2);
                line-height: var(--font-line-height-navigation-main-2);
                font-weight: var(--font-weight-bold);
                text-transform: uppercase;
            }

            // 3rd level
            ul {
                li {

                    button,
                    span,
                    a {
                        font-size: var(--font-size-navigation-main-3);
                        line-height: var(--font-line-height-navigation-main-3);
                        font-weight: var(--font-weight-normal);
                    }
                }
            }
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        text-decoration: none;
    }
}

@mixin font-navigation-mobile {

    // Remove global button mixin styles from all navigation buttons
    button {
        border-radius: 0;

        @if $enable-shadows {
            box-shadow: none;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        li {
            margin-top: var(--spacer-2);
            margin-bottom: var(--spacer-2);
            padding: 0;

            button {
                @include add-icon(chevron, after, $size: 1em);

                &::after {
                    margin-left: var(--spacer-2);
                }
            }
        }
    }

    button,
    span,
    a {
        color: var(--color-navigation-mobile-text);
        font-family: var(--font-family-sans-serif);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-navigation-mobile);
        line-height: var(--font-line-height-navigation-mobile);
        text-transform: uppercase;
        text-decoration: none;
        vertical-align: middle;
        transition: all 0.3s linear;

        &:hover {
            color: var(--color-navigation-mobile-text-hover);
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        text-decoration: none;
    }
}

// See also custom-reboot.scss
@mixin font-link {
    outline: none;
    text-decoration: $link-decoration;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        text-decoration: $link-hover-decoration;
    }
}

@mixin font-cta-link {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-normal);
    font-size: calc(var(--font-size-base) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-text) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    transition: all 0.3s linear;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-base);
        line-height: var(--font-line-height-text);
    }
}

@mixin font-process-bar {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-process-bar) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-process-bar) * #{$responsive-scale-factor});
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-process-bar);
        line-height: var(--font-line-height-process-bar);
    }
}

@mixin font-form-legend {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-form-legend) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-form-legend) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-form-legend);
        line-height: var(--font-line-height-form-legend);
    }
}

@mixin font-table-header {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-table-head) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-table-head) * #{$responsive-scale-factor});
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-table-head);
        line-height: var(--font-line-height-table-head);
    }
}

@mixin font-table-body {
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-normal);
    font-size: calc(var(--font-size-table-body) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-table-body) * #{$responsive-scale-factor});
    vertical-align: baseline;

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-table-body);
        line-height: var(--font-line-height-table-body);
    }
}

@mixin font-text {
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-normal);
    font-size: calc(var(--font-size-base) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-text) * #{$responsive-scale-factor});

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-base);
        line-height: var(--font-line-height-text);
    }
}

@mixin font-address {
    font-style: normal;
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-normal);
    font-size: calc(var(--font-size-address) * #{$responsive-scale-factor});
    line-height: calc(var(--font-line-height-address) * #{$responsive-scale-factor});

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-address);
        line-height: var(--font-line-height-address);
    }
}